import React from "react"

// Libraries
import PropTypes from "prop-types"

const VerticalTitle = ({ className, title, animated, animatedMobile }) => (
  <div
    className={`vertical-title ${className} ${animated && "animated"} ${
      animatedMobile && "animated--mobile"
    }`}
  >
    <h1 className="highlight">{title}</h1>
    <h1 className="highlight clone">{title}</h1>
  </div>
)

VerticalTitle.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  animated: PropTypes.bool,
  animatedMobile: PropTypes.bool,
}

VerticalTitle.defaultProps = {
  className: "",
  animated: false,
  animatedMobile: false,
}

export default VerticalTitle
